import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SwiperCore, { Navigation, Pagination as SwiperPagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
SwiperCore.use([Navigation, SwiperPagination, Scrollbar, A11y, Autoplay]);

import { Layout, PostCard, Pagination, PostSlide } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Home = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges
    const site = data.allGhostSettings.nodes[0];
    const homeContent = data.ghostPage || {};

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="tw-bg-white tw-relative">
                    <div className="
                    tw-bg-pjsBlue tw-text-pjsPink 
                    tw-flex tw-flex-col tw-items-center 
                    tw-absolute tw-z-20
                    tw-pt-0 
                    tw-font-sans tw-font-light tw-h-screen tw-w-screen
                    pjs-fade-away
                    ">
                        <div className="container tw-text-center tw-h-full tw-min-h-screen  tw-w-full tw-max-w-screen-xl tw-mx-auto tw-flex tw-flex-col tw-justify-center">
                            <div>
                                <h1 className="tw-mb-12 tw-text-pjsPink tw-text-9xl tw-font-normal tw-uppercase tw-tracking-wider">{site.title}</h1>
                                <p className="tw-mt-1 tw-mb-0 tw-text-6xl tw-font-normal tw-uppercase tw-tracking-widest">{site.description}</p>
                            </div>
                            {site.logo ?
                                <img className="tw-text-pjsPink tw-h-64 lg:tw-h-96 tw-absolute tw-z-10 tw-bottom-14 tw-right-1/2 lg:tw-right-20 tw-transform tw-translate-x-1/2 lg:tw-translate-x-0" src={site.logo} alt={site.title} />
                                : <Img className="tw-text-pjsPink tw-h-64 lg:tw-h-96 tw-absolute tw-z-10 tw-bottom-14 tw-right-1/2 lg:tw-right-20 tw-transform tw-translate-x-1/2 lg:tw-translate-x-0" fixed={data.file.childImageSharp.fixed} alt={site.title} />
                            }
                        </div>
                    </div>
                    <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    speed={750}
                    autoplay={
                        {
                            delay: 5000,
                        }
                    }
                    loop={true}
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    className="tw-h-screen tw-bg-white tw-text-pjsPink pjs-swipper"
                    >
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <SwiperSlide key={node.id} className="tw-h-screen">
                                <PostSlide post={node} className="tw-block" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                
                <div className="container extra-wide-container">
                    <section className="pjs-home-content tw-max-w-screen-md tw-mt-24 tw-mx-auto">
                        <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: homeContent.html }}
                        />
                    </section>
                    {/* <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} /> */}
                </div>
            </Layout>
        </>
    )
}

Home.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Home

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostSettings {
        nodes {
            logo
            title
            description
        }
    }
    file(relativePath: {eq: "ghost-icon.png"}) {
        childImageSharp {
            fixed(width: 30, height: 30) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    ghostPage(slug: {eq: "home"}) {
        id
        slug
        html
        url
    }
  }
`
